<template>
  <div class="_content">
    <a-row>
      <a-col :xs="{ span: 20, offset: 2 }" :lg="{ span: 14, offset: 5 }">
        <a-skeleton :loading="loading">
          <div v-html="data" />
        </a-skeleton>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: "",
      loading: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store.dispatch("GetPageAboutUs").then((response) => {
        this.loading = false;
        this.data = response.data.data;
      });
    },
  },
};
</script>
